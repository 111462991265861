import {
  Box,
  Text,
  Grid,
  Link,
  Modal,
  Icon,
  LegacyCard,
  IndexTable,
  TextField,
  useIndexResourceState,
} from "@shopify/polaris";
import { useState, useCallback, useEffect } from 'react';
import "./styles.customorder.css";
import { EditIcon, SearchIcon} from "@shopify/polaris-icons";
import "./styles.customorder.css";
import api from "../axiosClient";
import { getBadgeByStatus, timeAgo } from "../utlis";
import { useNavigate } from "react-router-dom";

export default function RelatedOrders({ email, id, shop, user , setLoading, name, setOrderDetail, orderDetail }) {
  const [orders, setOrders] = useState([]);
  const [active, setActive] = useState(false);
  const navigate = useNavigate();
  const [orderList, setOrderList] = useState([]);
  const [query, setQuery] = useState(false);

  const [tableHeader, setTableHeader] = useState([
    { title: 'Order'},
    { title: 'Order Date'},
    { title: 'Due Date' },    
    { title: 'Customer'},
    { title: 'Order Status'},
    { title: 'Task Status'},
    { title: 'Priority'},
    { title: 'Age'}
  ]);

  const tags = {
    AWAITING_PROOF: { title: "Awaiting Proof", tone: "attention" },
    AWAITING_DESIGN: {title:"Drawing Needed", tone: "critical"},
    SENT: {title:"Proof Sent", tone: "attention"},
    ACCEPTED: {title:"Proof Accepted", tone: "success"},
    REJECTED: {title:"Proof Rejected", tone: "critical"},
    PROOF_ADDED: {title:"Proof Added", tone: "info"},
    HOT_LIST: { title: "Hot List", tone: "critical-strong" },
    ATTENTION: { title:"Awaiting Attention", tone: "critical-strong"}
  }

  const resourceName = {
    singular: 'order',
    plural: 'orders',
  };
  
  useEffect(() => { 
    handleGetOrders();
    handleGetSettings();
  }, []);

  const handleGetSettings = () => {
    api.get(`/getOrdersByEmail?email=${email}`)
  .then((response) => {
    setOrders(response.data);
    
  })
  .catch((error) => {
    console.error("Error fetching settings:", error);
  });
  }

  const handleUpdateOrder = async (data) => {
    return await api.post("/updateRelatedOrder",data).then((result) => {
      if(result.data)
      {
        setOrderDetail(result.data);
      }
    });
  }

  const handleGetOrders = (url = `/getAllOrders?shop=${shop}`) => {
    setLoading(true);
    api.get(`${url}`).then((data) => {
      setOrderList(data.data);
      setLoading(false);
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    });
  }

  const handleSearch = () => {
    // Perform API search call with the current query value
    if (query) {
      handleGetOrders(`/getSearchOrders?shop=${shop}&term=${query}`)
    } else {
      handleGetOrders();
    }
  }

  const handleSubmit = async () => {
    setLoading(true);
    // Filter the orderList based on selected resources
    const selectedOrders = orderList.filter((order) =>
      selectedResources.includes(order.id)
    );
  
    // Loop through filtered orders and update each one
    await selectedOrders.forEach((order) => {
      handleUpdateOrder({
        related_order: {
          id: order.id,
          name: order.name,
        },
        order: {
          id: id,   // Assuming `id` and `name` are defined elsewhere in your component
          name: name,
        }
      });
    });
    setLoading(false);
    setActive(false);
  };

  const {selectedResources, allResourcesSelected, handleSelectionChange} =
  useIndexResourceState(orderList);
  
  const rowMarkup = orderList?.map(
    (
      {
        _id,
        shop,
        id,
        created_at,
        customer,
        due_date,
        financial_status,
        name,
        current_status,
        task_status,
        priority,
        tags: t,
    },
      index,
    ) => (
      
      <IndexTable.Row id={id} key={id} position={index} selected={selectedResources.includes(id)}>
        <IndexTable.Cell className="Polaris-IndexTable__TableCell tc-bg"><span className="pointer">{name}</span></IndexTable.Cell>
        <IndexTable.Cell><span className="pointer">{formatDate(created_at)}</span></IndexTable.Cell>
        <IndexTable.Cell><span className="pointer">{formatDate(due_date)}</span></IndexTable.Cell>
        <IndexTable.Cell><span className="pointer">{customer.first_name+"  "+customer.last_name}</span></IndexTable.Cell>
        <IndexTable.Cell><span className="pointer">
        <span>{ getBadgeByStatus(tags[current_status].title === "Drawing Needed" ? "Awaiting Proof" : tags[current_status].title)}</span>  
        </span></IndexTable.Cell>
        <IndexTable.Cell><span className="pointer"><span >{ getBadgeByStatus(task_status)}</span></span></IndexTable.Cell>
        <IndexTable.Cell><span className="pointer">
        <span>{getBadgeByStatus(priority ? priority : "Normal")}</span>  
        </span></IndexTable.Cell>
        <IndexTable.Cell><span className="pointer">{timeAgo(created_at)}</span></IndexTable.Cell>
        
      </IndexTable.Row>
    ),
  );


  function formatDate(dateString) {
    const date = new Date(dateString);

// Get the components of the date
const day = date.toDateString().split(' ')[0];
const month = date.toDateString().split(' ')[1];
const dayOfMonth = date.getDate();
const year = date.getFullYear();

// Format the date as "Thu May 16 2024"
    const formattedDate = `${month} ${dayOfMonth} ${year}`;
    return formattedDate;
  }


  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      handleSearch();
    }
  };

  const handleBlur = () => {
    handleSearch();
  };


  return (
    <>
      <Box id="artist-box">
        
      <div className="column-header">
          <Text as="h6" tone="subdued" fontWeight="semibold" >
          Related Orders
        </Text>  
        <span onClick={() => setActive(true)}><Icon source={EditIcon} tone="base" /></span>
            </div> 
          
            <div className="right-column-body">
        
        <Grid columns={{ xs: 12, sm: 12, md: 10, lg: 16, xl: 20 }} style={{ marginTop: "10px", width: "100%" }}>
            
            {orderDetail.related_orders?.map((order) => 
              <Grid.Cell columnSpan={{ xs: 4, sm: 4, md: 3, lg:4, xl: 5 }}>
                
                <Link url={`/app/order?id=${order.id}&shop=${shop}`}>              
                    {getBadgeByStatus(order.name)}
                </Link>
                
            </Grid.Cell>)
            }
          </Grid>  
      </div>  
      </Box>     
      <Modal
          size="fullScreen"
          open={active}
          onClose={() => setActive(false)}
          title="Orders List"
          
        >
        <Modal.Section>
        <div className="order-filter">
          <div style={{flex:1}} onKeyDown={handleKeyPress}>
          <TextField
                value={query}
                onChange={(e) => {setQuery(e) }}
                fullWidth
                placeholder="Filter Orders"
                prefix={<Icon source={SearchIcon} color="base" />}
                onBlur={handleBlur}
              />
            </div>
          </div>
        <LegacyCard >
              {tableHeader.length && <IndexTable
                
              resourceName={resourceName}
              itemCount={orderList.length}
              headings={tableHeader}
              onSelectionChange={handleSelectionChange}
              selectedItemsCount={
                allResourcesSelected ? 'All' : selectedResources.length
              }
            >
              {rowMarkup}
            </IndexTable>}
            </LegacyCard>
        </Modal.Section>
        <Modal.Section>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: "15px" }}>
            <div onClick={() => setActive(false)} style={{ padding: "4px 10px", minWidth: "60px" }} className="btn">Close</div>
            <div onClick={() => handleSubmit()} style={{ padding: "4px 10px", minWidth: "60px" }} className="btn print-btn">Add to related Orders</div>
      </div>
      </Modal.Section>
      </Modal>
    </>
  );
}
            
