import {
  Box,
  Page,
  Text,
  Button,
  DropZone,
  Icon,
  Collapsible,
  TextField,
  Grid,
  Modal,
} from "@shopify/polaris";
import { useState, useCallback, useEffect } from 'react';
import "./styles.customorder.css";
import {   XCircleIcon,ChevronDownIcon,CaretUpIcon, CaretDownIcon, AlertDiamondIcon, CheckSmallIcon, StatusActiveIcon, ChatIcon } from "@shopify/polaris-icons";
import api from "../axiosClient";
import "./styles.customorder.css";
import ModalExample from "./pdf";
import ItemsDetail from "./ItemsDetail";


export default function OrderStatus({orderId, shop}) {
  const [popoverActive, setPopoverActive] = useState("");
  const [orderList, setOrderList] = useState([]);
  const [proofFile, setProofFile] = useState([]);
  const [file, setFile] = useState({});
  const [comment, setComment] = useState("");
  const [proofAdded, setProofAdded] = useState([]);
  const [proofSent, setProofSent] = useState([]);
  const [proofAccepted, setProofAccepted] = useState([]);
  const [proofRejected, setProofRejected] = useState([]);
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toggleFileActive = useCallback(() => setFileActive((activeFile) => !activeFile), []);
  const [activeFile, setFileActive] = useState(false);
  const [activeMsg, setActiveMsg] = useState({active:false, title: "", msg: ""});
  const toggleActiveMsg = useCallback((title = "", msg = "") => {
    setActiveMsg(prevActiveMsg => ({
        active: !prevActiveMsg.active,
        title: title,
        msg: msg
    }));
}, []);

  function formatDate(dateString) {
    const options = {
      day: '2-digit',
      month: 'long',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };
    
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  }

  useEffect(() => { 
    console.log("params", orderId)
    handleGetOrders(orderId);
  }, []);

  const handleGetOrders = (id) => {
    api.get(`/getOrderDetailsById?shop=${shop}&id=${id}`).then((data) => {
      setOrderList(data.data);
    });
  }

  useEffect(() => { 
    if(proofFile.length)
    setPopoverActive(`basic-collapsible-${proofFile.length-1}`)
  }, [proofFile]);

  
  useEffect(() => { 
    if (orderList?.files?.length > 0) {
      setProofFile(orderList.files.filter((f) => f.isProofSent))
      setProofAdded(orderList.files.filter((f) => f.isProofAdded))
      setProofSent(orderList.files.filter((f) => f.isProofSent))
      setProofAccepted(orderList.files.filter((f) => f.customerAccepted))
      setProofRejected(orderList.files.filter((f) => f.customerRejected))
    }else setProofFile([])
  }, [orderList]);
  
    const handleApproveFile = async () => {
      console.log("Approve", file);
      const data = {
        id: orderList.id,
        shop: orderList.shop,
        name: proofFile[proofFile.length - 1].name,
        link: proofFile[proofFile.length-1].link,
        file: {
          "files.$.customerAccepted": true,
          "files.$.customerRejected": false,
          "files.$.customerAcceptedDate": new Date().toISOString(),
          "files.$.customerComment": comment,
        }
      }
      await api.post("/orderDetailsFileUpdate", data).then((result) => {
        if(result.data.id){
          setOrderList(result.data);
          setActiveMsg({ active: true, type: "success", msg: "Proof File Approved Successfully" });
        }
      }).catch(err => setActiveMsg({active:true,type:"error", msg:"Failed to Approve Proof File"})); 
      toggleFileActive();
    }
  
    const handleRejectFile = async () => {
      console.log("Reject", file);
      const data = {
        id: orderList.id,
        shop: orderList.shop,
        name: proofFile[proofFile.length-1].name,
        file: {
          "files.$.customerRejected": true,
          "files.$.customerAccepted": false,
          "files.$.customerRejectedDate": new Date().toISOString(),
          "files.$.customerComment": comment,
        }
      }
      await api.post("/orderDetailsFileUpdate", data).then((result) => {
        if(result.data.id){
          setOrderList(result.data);
          setActiveMsg({active:true,type:"success", msg:"Proof File Rejected Successfully"});
         }
      }).catch(err => setActiveMsg({active:true,type:"error", msg:"Failed to Reject Proof File"})); 
      toggleFileActive();
      toggleActive();
    }
  
    const handleDropZoneDrop = 
    (_dropFiles, acceptedFiles, _rejectedFiles) => {
       if(acceptedFiles.length) {
 
         let file = acceptedFiles[0];
 
         // Make new FileReader
         let reader = new FileReader();
 
         // Convert the file to base64 text
         reader.readAsDataURL(file);
 
         // on reader load somthing...
         reader.onload = () => {
 
           // Make a fileInfo Object
           let fileInfo = {
             name: file.name,
             type: file.type,
             size: Math.round(file.size / 1000) + ' kB',
             base64: reader.result,
             file: file,
           };
           const data = {
            id: orderList.id,
            shop: orderList.shop,
            file: fileInfo 
           }
           console.log(data)
           
           api.post("/orderDetailsUploadNewCustomerProof", data).then((result) => {
             if(result.data.id)
             setOrderList(result.data);
           });
           
         }
       }
      };
  
    const handleViewFile = (file) => {
        setFile(file);
        setFileActive(true);
    }

  //change here
  
  return (
    <div className="proof-pag-wrapper">
    <Page fullWidth >
      <div style={{padding:"40px", textAlign:"center"}}>
        <span style={{ fontSize: "34px", textTransform: "capitalize", fontFamily: "Cormorant, serif", fontWeight: 400 }}>Your Order Proof</span>
      </div>
      <div style={{textAlign:"left"}}>
        <p style={{fontSize:"16px", textTransform:"capitalize", fontFamily:"News Gothic No 2, sans-serif", fontWeight:400, lineHeight:1.5}}>Please review your order proof and either <b>Approve Proof</b> or <b>Reject Proof</b>. If you have changes, please leave them in the comment box.<b> Please note that if viewing your Proof on a mobile device, you must scroll through the pages of your Proof PDF by swiping horizontally on the document.</b></p>
      </div>
      {proofFile.length > 0  && <div
        style={{
          borderRadius: "8px",
          border: "1px solid #DEE2E6",
          overflow: "hidden",
          background: "#FFFFFF",
          marginTop:"30px"
        }}
      >
        
        {proofFile.map((item, index) => index===proofFile.length-1 && <>
          <div onClick={() => handleViewFile(item)} style={{ backgroundColor: "#F8F9FA", padding: "1em", display: "flex", justifyContent: "space-between", padding: "1em", fontWeight: 'bold', cursor: "pointer", alignItems: "center", borderBottom: "1px solid #DEE2E6", }}>
            <span style={{ fontSize: "16px", textTransform: "capitalize", fontFamily: "News Gothic No 2, serif", fontWeight: 600, color: "#1B1B1B" }}>{"Proof #"}{index + 1}</span>
            <span style={{ height: "25px", width: "25px" }}><Icon source={popoverActive === `basic-collapsible-${index}` ? CaretUpIcon : CaretDownIcon} tone="inherit" /></span>
          </div>
        </>)} 
      </div>}
      <Modal
          size="fullScreen"
          open={activeFile}
          onClose={toggleFileActive}
          title="Order Proof"
        >
        <Modal.Section>
        <ModalExample fileUrl={file?.link} />
        </Modal.Section>
        {!proofFile.find((item) => item.customerAccepted || item.customerRejected) && <Modal.Section>

          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: "15px" }}>
          <div onClick={() => toggleActive()} style={{ padding: "4px 10px", minWidth: "60px" }} className="btn reject-btn">Reject Proof</div>
          <div onClick={() => handleApproveFile()} style={{ padding: "4px 10px", minWidth: "60px" }} className="btn approved-btn">Approve Proof</div>
          </div>
         
          {/* <div style={{ display: 'flex', justifyContent: 'flex-end', gap: "15px" }}>
            <div onClick={handleApproveFile} className="btn" style={{
              display: "flex", gap: "5px", padding: "15px 25px",
              background: "#f41289",
              border: "1.5px solid #f41289",
              color: "#fff",
              fontSize: "14px",
              textDecoration: "none",
            }}>
              <Icon source={CheckSmallIcon} />
              APPROVE PROOF
            </div>
            
        
        
            <div onClick={toggleActive} className="btn" style={{
              display: "flex", gap: "5px", padding: "17px 25px",
              background: "#f41289",
              border: "1.5px solid #f41289",
              color: "#fff",
              fontSize: "14px",
              textDecoration: "none",
            }}>
        
              REJECT PROOF
            </div>
          </div> */}
            
          
        </Modal.Section>}
      </Modal>
      <Modal
          size="small"
          open={active}
          onClose={toggleActive}
        title="Reject Proof"
        >
        <Modal.Section>
        <div style={{ padding: "0px 0px", display: "inline-grid", textAlign: "left", gap: "10px", width:"100%", boxShadow: "0 2px 1px rgba(170, 170, 170, 0.25)"}}>
        <span>Your comments (optional)</span>
        <TextField  placeholder="Add a comment..."
                value={comment}
                onChange={setComment}
                multiline={3}
                autoComplete="off" />
        
      </div>
          </Modal.Section>
          <Modal.Section>

<div style={{ display: 'flex', justifyContent: 'flex-end', gap: "15px" }}>
    <div onClick={() => handleRejectFile()} style={{ padding: "4px 10px", minWidth: "60px" }} className="btn reject-btn">Reject Proof</div>
  </div>
  </Modal.Section>
      </Modal>
      <Modal
      size="small"
      open={activeMsg.active}
      onClose={toggleActiveMsg}
      title={activeMsg.type === 'success' ? "Success" : "Error"}
      secondaryActions={{
        content: 'Close',
        onAction: toggleActiveMsg,
      }}
    >
      <Modal.Section>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {activeMsg.type === 'success' ? (
            <>
              <span style={{width:"40px"}}><Icon source={StatusActiveIcon} tone="success" /></span>
              <span >{activeMsg.msg}</span>
            </>
          ) : (
            <>
              <span style={{width:"40px"}}><Icon source={AlertDiamondIcon}  tone="critical"/></span>
              <span style={{ marginLeft: 8 }}>{activeMsg.msg}</span>
            </>
          )}
        </div>
      </Modal.Section>
    </Modal>
      {proofFile.filter((item) => item.customerAccepted || item.customerRejected).map((item) => <div style={{ padding: "40px 0px", display: "inline-grid", textAlign: "left", gap: "10px", width:"100%", boxShadow: "0 2px 1px rgba(170, 170, 170, 0.25)"}}>
        <span>{item.customerAccepted ? `You accepted this proof on ${formatDate(item.customerAcceptedDate)}` : item.customerRejected ? `You rejected this proof on ${formatDate(item.customerRejectedDate)}` : ""} </span>
      </div>)}
      
      
      </Page>
      </div>
  );
}

 
            
