import {
  Box,
  Text,
  Icon,
  Button,
  Select,
} from "@shopify/polaris";
import { useState, useCallback, useEffect } from 'react';
import "./styles.customorder.css";
import { CheckCircleIcon} from "@shopify/polaris-icons";
import "./styles.customorder.css";
import api from "../axiosClient";

export default function ArtistManagement({ artist, id, shop, user }) {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(artist?.email? artist.email : null);
  const [artistObj, setArtistObj] = useState(artist);
  const [options, setOptions] = useState([]);
  const [artists, setArtists] = useState([]);
  const handleSelectChange = useCallback(
    (value) => {
      setSelected(value);
      console.log(value);
      const selectedArtist = artists.find(a => a.email === value);
    console.log("selectedArtist",selectedArtist);
            handleUpdateOrder({
        "shop":shop,
        "id":id,
              "artist": {
                ...selectedArtist,
                date: new Date().toISOString()
        }
    })
    },
    [],
  );
  
  useEffect(() => { 
    console.log("artist", artist);
    handleGetSettings();
  }, []);
  useEffect(() => { 
    setSelected(artist?.email ? artist.email : null);
    setArtistObj(artist);
  }, [artist]);

  const handleGetSettings = () => {
    api.get(`/getSettingsByStore?shop=${shop}`)
  .then((response) => {
    const artists = response.data.artist;
    setArtists(artists);
    const options = artists.map((artist) => ({
      label: artist.name, 
      value: artist.email,
      prefix: <Icon source={CheckCircleIcon} />,
    }));
    setOptions(options);
    
  })
  .catch((error) => {
    console.error("Error fetching settings:", error);
  });
  }

  const handleUpdateOrder = (data) => {
    api.post("/updateOrderDetails",data).then((result) => {
      if(result.data.artist)
        setArtistObj(result.data.artist);
    });
  }




  return (
    <Box id="artist-box">
          <div className="column-header">
          <Text as="h6" tone="subdued" fontWeight="semibold" >
              Artist Management
              </Text>  
              
            </div> 
            <div className="right-column-body">
            
                
    
               {artistObj && <div style={{display:"flex", alignItems: "center", gap:"5px", margin:"0px 0px 10px"}}>
                    
                    <span><Icon  size="large" source={CheckCircleIcon} tone="success" /></span>
                    <Text as="h4" tone="subdued" fontWeight="normal" >
                    Assigned to {artistObj.name} on {new Date(artistObj.date).toLocaleDateString()}
                    </Text> 
                  
              </div>}
              <Select
          placeholder="Select to reassign"
          options={options}
          onChange={handleSelectChange}
          value={selected}
          disabled={!user?.artist?.permissions?.admin}
    />
            </div>  
          </Box>      
  );
}
            
