import {
  Box,
  Page,
  Text,
  Button,
  Icon,
  Popover,
  Grid,
  ButtonGroup,
  Link,
  Badge,
  Modal,
  ActionList,
} from "@shopify/polaris";
import { useState, useCallback, useEffect } from 'react';
import "./styles.customorder.css";
import {  DuplicateIcon,ArchiveIcon, ArrowLeftIcon,PrintIcon,MenuVerticalIcon, ViewIcon, NoteIcon, ClipboardCheckIcon, LabelPrinterIcon } from "@shopify/polaris-icons";
import api from "../axiosClient";
import "./styles.customorder.css";
import CustomerDetail from "./CustomerDetail";
import ItemsDetail from "./ItemsDetail";
import ArtistManagement from "./ArtistManagement";
import OrderNotes from "./OrderNotes";
import OrderComments from "./OrderComments";
import OrderInternalFile from "./OrderInternalFile";
import OrderProofFile from "./OrderProofFile";
import OrderDetailProofSent from "./OrderProofSent";
import { useNavigate } from "react-router-dom";
import CustomSpinner from "./Loader";
import OrderItemsContainer from "./OrderItemsContainer";
import RelatedOrders from "./RelatedOrders";


export default function OrderDetail({orderId, shop}) {
  const [popoverActive, setPopoverActive] = useState(true);
  const [orderList, setOrderList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );

  const [user, setUser] = useState(null);
  const [error, setError] = useState(false);

  const [activeAction, setActiveAction] = useState(false);
  const toggleActiveAction = useCallback(() => setActiveAction((activeAction) => !activeAction), []);
  const [activeActionWeb, setActiveActionWeb] = useState(false);
  const toggleActiveActionWeb = useCallback(() => setActiveActionWeb((activeActionWeb) => !activeActionWeb), []);

  useEffect(() => {
    try {
      const storedUser = JSON.parse(localStorage.getItem('user'));
      if (storedUser && storedUser?.artist) {
        setUser(storedUser);
      } else {
        setError(true);
      }
    } catch (err) {
      setError(true);
    }
  }, []);

  useEffect(() => {
    handleGetOrders(orderId);
  }, []);

  const handleGetOrders = (id) => {
    setLoading(true);
    api.get(`/getOrderDetailsById?shop=${shop}&id=${id}`).then((data) => {
      if (data.data.attention && user?.artist?.permissions?.admin) {
        handleUpdateOrder({
          "shop":data.data.shop,
          "id":data.data.id,
          "attention": false
       })
      }
      if (data.data?.id) {
        setOrderList(data.data);
        setError(false);
      } else {
        setError(true);
      }
      setLoading(false);
    }).catch((error) => {
      setError(true);
      setLoading(false);
      console.log(error);
    });
  }

  
  const handleUpdateOrder = (data) => {
    setLoading(true);
  api.put("/updateOrder",data).then((result) => {
    handleGetOrders(orderId);
    setLoading(false);
  }).catch((error) => {
    setLoading(false);
  });
  }
  
  function timeAgo(timestamp) {
    const now = new Date();
    const past = new Date(timestamp);
    const secondsPast = Math.floor((now - past) / 1000);
  
    if (secondsPast < 60) {
        return `${secondsPast} second${secondsPast === 1 ? '' : 's'}`;
    }
  
    const minutesPast = Math.floor(secondsPast / 60);
    if (minutesPast < 60) {
        return `${minutesPast} minute${minutesPast === 1 ? '' : 's'}`;
    }
  
    const hoursPast = Math.floor(minutesPast / 60);
    if (hoursPast < 24) {
        return `${hoursPast} hour${hoursPast === 1 ? '' : 's'}`;
    }
  
    const daysPast = Math.floor(hoursPast / 24);
    if (daysPast < 7) {
        return `${daysPast} day${daysPast === 1 ? '' : 's'}`;
    }
  
    const weeksPast = Math.floor(daysPast / 7);
    if (weeksPast > 0) {
        return `${weeksPast} week${weeksPast === 1 ? '' : 's'}`;
    }
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
  
    // Get the components of the date
    const dayOfMonth = date.getDate();
    const year = date.getFullYear();
    const month = date.toLocaleString('default', { month: 'long' });
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
  
    // Format the date as "July 8, 2024 at 4:40 pm"
    const formattedDate = `${month} ${dayOfMonth}, ${year} at ${formattedHours}:${formattedMinutes} ${ampm}`;
    return formattedDate;
  }

  const handlePrintPurshaseOrder = async () => {
    const data = {
      id: orderList.id,
      user: user.artist.name,
      email: user.artist.email
    }
    await api.post("/OPUsers", data).then((result) => {
      if(result.data.id)
        setOrderList(result.data);
    });
    if (orderList.PO_link) {
      window.open(orderList.PO_link, '_blank', 'noopener,noreferrer');
      return;
    } else {
      setLoading(true);
      api.get(`/htmlOPPdf?id=${orderList.id}`).then((result) => {
        if (result.data.id) {
          setOrderList(result.data);
          window.open(result.data.PO_link, '_blank', 'noopener,noreferrer');
        }
        setLoading(false);
      }).catch((error) => setLoading(false));
    }     
  }

  return (
    <>
      <div className="fixedElement">
      <div className="block A">
      <Link url="/app/customorder">
     <span style={{display:"flex", alignItems:"center", color:"#000000"}}><span style={{paddingRight:"10px"}}> <Icon
  source={ArrowLeftIcon}
  tone="inherit"
        /></span>
        <Text variant="headingXl" as="span" tone="subdued" >
        {orderList?.name && orderList.name}
          </Text>
          </span>
          </Link>
          <Badge progress={orderList.financial_status === "paid" ? "complete" : "incomplete"} tone={orderList.financial_status === "paid" ? "default" : "warning"}>{orderList.financial_status}</Badge>
          <Badge progress={orderList.display_status ? "incomplete" : "complete"} tone={orderList.display_status ? "attention" : "default"}>{orderList.display_status ? "Unfulfilled" : "Fulfilled"}</Badge>
        </div>
        <div className="block B">
          <div id="web" className="web">    
        <Popover
        active={activeActionWeb}
        activator={<Button onClick={toggleActiveActionWeb} disclosure>
        More actions
      </Button>}
        autofocusTarget="first-node"
              onClose={toggleActiveActionWeb}
              
      >
        <ActionList
                actionRole="menuitem"
          items={[
            {
              content: 'Print Order', icon: LabelPrinterIcon,
              onAction: () => {
                handlePrintPurshaseOrder()
                toggleActiveActionWeb()
              }
              },
            { content: <Link className="block B" url={`https://${shop}/pages/approved-order-proof?order=${orderId}`} external target="_blank">              
            <div style={{color:"#000000"}}>View Approval Page</div>
            </Link>, icon: DuplicateIcon, onAction: () => toggleActiveActionWeb() },
            {content: <Link url={`https://admin.shopify.com/store/${shop.split('.')[0]}/orders/${orderId}`} external target="_blank">              
            <div style={{color:"#000000"}} onClick={toggleActiveActionWeb}>View Shopify Order</div>
            </Link>  , icon: DuplicateIcon, onAction: () => toggleActiveActionWeb()},
            !(orderList?.priority === "Rush") && {
              content: 'Mark as Rush', icon: ArchiveIcon,
              onAction: () => {
                handleUpdateOrder({
                  "shop": shop,
                  "id": Number(orderId),
                  "priority": "Rush"
                })
                toggleActiveActionWeb()
              }
            },
          ]}
        />
            </Popover>
            </div>
      <div className="mobile">
          <Popover
        active={activeAction}
        activator={
        <span onClick={toggleActiveAction} ><Icon source={MenuVerticalIcon} /></span>}
        autofocusTarget="first-node"
              onClose={toggleActiveAction}
              
      >
        <ActionList
                actionRole="menuitem"
          items={[
            {
              content: 'Print Order', icon: LabelPrinterIcon,
              onAction: () => {
                handlePrintPurshaseOrder()
                toggleActiveAction()
              }
              },
            { content: <Link className="block B" url={`https://${shop}/pages/approved-order-proof?order=${orderId}`} external target="_blank">              
            <div style={{color:"#000000"}}>View Approval Page</div>
            </Link>, icon: DuplicateIcon, onAction: () => toggleActiveAction() },
            {content: <Link url={`https://admin.shopify.com/store/${shop.split('.')[0]}/orders/${orderId}`} external target="_blank">              
            <div style={{color:"#000000"}} onClick={toggleActiveAction}>View Shopify Order</div>
            </Link>  , icon: DuplicateIcon, onAction: () => toggleActiveAction()},
            !(orderList?.priority === "Rush") && {
              content: 'Mark as Rush', icon: ArchiveIcon,
              onAction: () => {
                handleUpdateOrder({
                  "shop": shop,
                  "id": Number(orderId),
                  "priority": "Rush"
                })
                toggleActiveAction()
              }
            },
          ]}
        />
      </Popover>
          
        </div>
        </div>
        </div>
    <Page fullWidth 
    >
      
      <CustomSpinner loading={loading} />
      <>
      {orderList?.name && <Box padding={{ xs: "200", sm: "300", md: "0", lg: "0", xl: "0" }}>
        <div className="fixedElement-Lower">
          <Box>
          <Text variant="bodySm" as="span" fontWeight="regular" >
            {`${formatDate(orderList?.created_at)} from ${orderList.source_name === "web"?"Online Store" :orderList.source_name === "shopify_draft_order" ? "Draft Orders" : orderList.source_name}`}
          </Text>
          </Box>
        </div>
        
        {/* {orderList.current_status === "SENT" && <OrderProofWaitingBanner setLoading={setLoading} user={user} setOrderList={setOrderList} id={orderList.id} shop={orderList.shop} order={orderList} />}
        {orderList.current_status === "REJECTED" && <OrderProofRejectedBanner setLoading={setLoading} user={user} setOrderList={setOrderList} id={orderList.id} shop={orderList.shop} order={orderList} transactions={["Len Smothermon has rejected your proof on 13 Mar at 15:54", "Len Smothermon has accepted your proof on 16 Mar at 05:54"]} />}
        {orderList.current_status === "ACCEPTED" && <OrderProofAcceptedBanner setLoading={setLoading} user={user} setOrderList={setOrderList} id={orderList.id} shop={orderList.shop} order={orderList} transactions={["Len Smothermon has rejected your proof on 13 Mar at 15:54", "Len Smothermon has accepted your proof on 16 Mar at 05:54"]} />} */}
       
        <Grid columns={{ xs: 1, sm: 1, md: 6, lg: 12, xl: 12 }}>
          <Grid.Cell columnSpan={{ xs: 1, sm: 1, md: 3, lg: 8, xl: 8 }}>
                <OrderItemsContainer setLoading={setLoading} user={user} setOrderList={setOrderList} orderList={orderList} />
                <ItemsDetail user={user} items={orderList.line_items} />
            <OrderDetailProofSent id={orderList.id} shop={orderList.shop} files={orderList.files} setOrderList={setOrderList} />
            <div className="web">{orderList?.shop && <OrderComments id={orderList.id} shop={orderList.shop} setOrderList={setOrderList} user={user} orderComments={orderList.comments || []} />}</div>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 1, sm: 1, md: 3, lg: 4, xl: 4 }}>
            <div className="order-detail-right">
                  {(orderList?.customer) && <CustomerDetail user={user} setOrderList={setOrderList} orderList={orderList} id={orderList.id} shop={orderList.shop} customer={orderList.customer} setLoading={setLoading} />}
                  {orderList?.contact_email && <RelatedOrders orderDetail={orderList} setLoading={setLoading} setOrderDetail={setOrderList} name={orderList.name} id={orderList.id} shop={orderList.shop} email={orderList.contact_email} />}    
                  {orderList?.shop && <OrderNotes id={orderList.id} shop={orderList.shop} setOrderList={setOrderList} ordernotes={orderList.note} />}    
              {orderList?.shop && <OrderInternalFile setLoading={setLoading} id={orderList.id} shop={orderList.shop} files={orderList.files} setOrderList={setOrderList} user={user}  />}
              {orderList?.shop && <OrderProofFile setLoading={setLoading} id={orderList.id} shop={orderList.shop} files={orderList.files} setOrderList={setOrderList} user={user}  />}
              {(user?.artist?.permissions?.admin && orderList?.shop) && <ArtistManagement artist={orderList.artist} id={orderList.id} shop={orderList.shop} user={user} />}
              <div className="mobile">{orderList?.shop && <OrderComments id={orderList.id} shop={orderList.shop} setOrderList={setOrderList} user={user} orderComments={orderList.comments || []} />}</div>
            </div>
          
          </Grid.Cell>
        </Grid>
      </Box>}
      {(!loading && error ) && <div className="spinner-container">
          <div class="Polaris-IndexTable">
            <div class="Polaris-IndexTable__IndexTableWrapper">
              <div class="Polaris-IndexTable__EmptySearchResultWrapper">
                <div class="Polaris-LegacyStack Polaris-LegacyStack--vertical Polaris-LegacyStack--alignmentCenter">
                  <div class="Polaris-LegacyStack__Item">
                    <img alt="Empty search results" src="data:image/svg+xml,%3csvg width='60' height='60' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M41.87 24a17.87 17.87 0 11-35.74 0 17.87 17.87 0 0135.74 0zm-3.15 18.96a24 24 0 114.24-4.24L59.04 54.8a3 3 0 11-4.24 4.24L38.72 42.96z' fill='%238C9196'/%3e%3c/svg%3e" draggable="false" />
                  </div>
                  <div class="Polaris-LegacyStack__Item">
                    <p class="Polaris-Text--root Polaris-Text--headingLg">No orders found</p>
                  </div>
                  <div class="Polaris-LegacyStack__Item">
                    <span class="Polaris-Text--root Polaris-Text--subdued">
                      <p>Try changing the order id or make sure it's a custom order</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>}
    </>
      </Page>
      <Modal
          size="fullScreen"
          open={false}
          onClose={toggleActiveAction}
          title={"Actions"}
        >
        <Modal.Section>
          
          <div className="mobile-action-modal">
          <Link className="block B" url={`https://${shop}/pages/approved-order-proof?order=${orderId}`} external target="_blank">              
            <div className="btn"><span ><Icon source={NoteIcon} /></span>View Approval Page</div>
            </Link>
        <Link url={`https://admin.shopify.com/store/${shop.split('.')[0]}/orders/${orderId}`} external target="_blank">              
            <div className="btn"><span ><Icon source={ViewIcon} /></span>View Shopify Order</div>
          </Link>  
            {orderList?.current_status === "ACCEPTED" && <div onClick={handlePrintPurshaseOrder} className="btn"><span ><Icon source={PrintIcon} /></span>Print Order</div>}
          {!(orderList?.priority === "Rush")&& <div onClick={() => handleUpdateOrder({
                  "shop": shop,
                  "id": Number(orderId),
                  "priority": "Rush"
                })} className="btn"><span ><Icon source={ClipboardCheckIcon} /></span>Mark as Rush</div>}</div>
      
      </Modal.Section>
      </Modal>
      </>
  );
}

 
            
