import {
  Box,
  Text,
  Icon,
  Badge,
  Button,
  Modal,
  Popover,
  ActionList,
} from "@shopify/polaris";
import { useState, useCallback, useEffect, useRef } from 'react';
import "./styles.customorder.css";
import { UploadIcon, XCircleIcon, ViewIcon} from "@shopify/polaris-icons";
import "./styles.customorder.css";
import api from "../axiosClient";
import ModalExample from "./pdf";
import OrderProofWaitingBanner from "./OrderProofWaitingBanner";
import OrderProofRejectedBanner from "./OrderProofRejectedBanner";
import OrderProofAcceptedBanner from "./OrderProofAcceptedBanner";
import ItemsDetail from "./ItemsDetail";
import { getBadgeByStatus } from "../utlis";

export default function OrderItemsContainer({ setLoading,  user, setOrderList, orderList }) {
  const [active, setActive] = useState(false);
  const [activePopover, setActivePopover] = useState(false);
  const [activePopoverCurrentStatus, setActivePopoverCurrentStatus] = useState(null);

    function formatDate(dateString) {
      const options = {
        day: '2-digit',
        month: 'long',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      };
      
      const date = new Date(dateString);
      return date.toLocaleString('en-US', options);
    }
  
    const handleUpdateOrder = (data) => {
      api.put("/updateOrder",data).then((result) => {
        setOrderList(result.data);
      });
    }
  
    const taskList = [
      {
        title: "Proof Needed",
        color: "success",
      },
      {
        title: "Drawing Needed",
        color: "critical",
      },
      {
        title: "Proof Added",
        color: "info",
      },
      {
        title: "Design Approval Needed",
        color: "warning",
      },
      {
        title: "Event List Needed",
        color: "warning",
      },
      {
        title: "Proof Revision Needed",
        color: "warning",
      },
      {
        title: "Pending Approval",
        color: "attention",
      },
      {
        title: "In production",
        color: "warning",
      }
    ];
    const priorityList = [
      {
        title: "Rush",
        color: "critical",
      },
      {
        title: "Normal",
        color: "info",
      },
    ];
    
    const statusList = [
      {
        title: "Awaiting Proof",
        value: "AWAITING_PROOF",
        color: "attention",
      },
      {
        title: "Proof Sent",
        value: "SENT",
        color: "info",
      },
      {
        title: "Proof Accepted",
        value: "ACCEPTED",
        color: "success",
      },
      {
        title: "Proof Rejected",
        value: "REJECTED",
        color: "critical",
      },
      {
        title: "Fulfilled",
        value: "FULFILLED",
        color: "critical",
      }
    ];
  
  
    const tags = {
      AWAITING_PROOF: { title: "Awaiting Proof", tone: "attention" },
      AWAITING_DESIGN: {title:"Drawing Needed", tone: "critical"},
      SENT: {title:"Proof Sent", tone: "attention"},
      ACCEPTED: {title:"Proof Accepted", tone: "success"},
      REJECTED: {title:"Proof Rejected", tone: "critical"},
      PROOF_ADDED: {title:"Proof Added", tone: "info"},
      HOT_LIST: { title: "Hot List", tone: "critical-strong" },
      ATTENTION: { title: "Awaiting Attention", tone: "critical-strong" },
      FULFILLED: {
        title: "Fulfilled",
        tone: "info"
      },
    }

  return (<>
   
            <div style={{ gap: "20px"}}>
      
        <div style={{ margin: "0px 0px 20px" }}>
            <Box id="order-conatainer-box" as="section" >
          
          <div className="column-body">
          <div className="pointer" style={{display:"flex", justifyContent:"flex-start", padding:"5px 0px", gap:"10px"}}>
          <Popover
          active={activePopoverCurrentStatus}
          activator={
            
            <span onClick={() => setActivePopoverCurrentStatus(true)}>{ getBadgeByStatus(tags[orderList.current_status].title === "Drawing Needed" ? "Awaiting Proof" : tags[orderList.current_status].title)}</span>
          }
          onClose={() => setActivePopoverCurrentStatus(null)}
        >
          <ActionList
            items={statusList.map((badge) => ({
              content: (
                 getBadgeByStatus(badge.title,orderList.current_status===badge.title)
              ),
              onAction: () => {
                const updatedOrderList = {...orderList, current_status: badge.value };
                setOrderList(updatedOrderList);
                setActivePopoverCurrentStatus(null);
                handleUpdateOrder({
                  "shop": orderList.shop,
                  "id": orderList.id,
                  "current_status": badge.value
                })
              },
            }))}
          />
            </Popover>
            <Popover
          active={activePopover}
          activator={
            <span onClick={() => setActivePopover(true)}>
              { getBadgeByStatus(orderList.task_status)}
              </span>
          }
          onClose={() => setActivePopover(null)}
        >
          <ActionList
            items={taskList.map((badge) => ({
              content: (
                 getBadgeByStatus(badge.title,orderList.task_status===badge.title)
              ),
              onAction: () => {
                const updatedOrderList = {...orderList, task_status: badge.title };
                setOrderList(updatedOrderList);
                setActivePopover(null);
                handleUpdateOrder({
                  "shop": orderList.shop,
                  "id": orderList.id,
                  "task_status": badge.title
                })
              },
            }))}
          />
              </Popover>
              {orderList.priority && <span>{getBadgeByStatus(orderList.priority)}</span>}
          </div> 
        {orderList.current_status === "SENT" && <OrderProofWaitingBanner setLoading={setLoading} user={user} setOrderList={setOrderList} id={orderList.id} shop={orderList.shop} order={orderList} />}
        {orderList.current_status === "REJECTED" && <OrderProofRejectedBanner setLoading={setLoading} user={user} setOrderList={setOrderList} id={orderList.id} shop={orderList.shop} order={orderList} />}
            {orderList.current_status === "ACCEPTED" && <OrderProofAcceptedBanner setLoading={setLoading} user={user} setOrderList={setOrderList} id={orderList.id} shop={orderList.shop} order={orderList} />}
          </div>   
              </Box>
                </div>
      
      </div>
     
    </>
  );
}
            
